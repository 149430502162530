import React from "react";
import icon1 from "../../assets/image/media/develop/png/DevMultiLanguage.png";
import icon2 from "../../assets/image/media/develop/png/DevParallel.png";
import icon3 from "../../assets/image/media/develop/png/DevCrossPlatform.png";
import icon4 from "../../assets/image/media/develop/png/DevIntegration.png";
import icon5 from "../../assets/image/media/develop/png/DevHighAvail.png";
import icon6 from "../../assets/image/media/develop/png/DevElasticScaling.png";
import FeatureCard2 from "../../components/FreatureCard2/FreatureCard2";
const items = [
  {
    icon: icon1,
    title: "Multi-language",
    content:
      "Our dev team codes in a range of languages to create efficient, stable applications based on the most up-to-date technologies.",
  },
  {
    icon: icon2,
    title: "Parallel development",
    content:
      "We break down the process into independent workflows, allowing us to work on several elements at once — maximizing efficiency.",
  },
  {
    icon: icon3,
    title: "Cross-platform",
    content:
      "Our development process enables us to build a single codebase and then quickly and easily deploy to multiple platforms.",
  },
  {
    icon: icon4,
    title: "Tight integration",
    content:
      "We plan and develop effective integrations—allowing your product to work seamlessly with existing systems or applications.",
  },
  {
    icon: icon5,
    title: "High availability",
    content:
      "We design and configure your hosting environment to ensure the highest levels of uptime, performance, and responsiveness.",
  },
  {
    icon: icon6,
    title: "Elastic scaling",
    content:
      "Both the infrastructure and applications are designed to be easily scalable on-demand without requiring significant rework.",
  },
];

const Feature = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-8 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83">
                And here’s what’s happening behind the scenes:
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard2 options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
