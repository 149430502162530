import React from "react";
import Brand1 from "../../assets/image/media/home/png/brand-1.png";
import Brand2 from "../../assets/image/media/home/png/brand-2.png";
import Brand3 from "../../assets/image/media/home/png/brand-3.png";
import Brand4 from "../../assets/image/media/home/png/brand-4.png";

const Brands = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-15 pt-lg-22 pb-13 pb-lg-18 border-top">
        <p className="font-size-6 text-center pb-13">Products</p>
        <div className="row align-items-center">
          <div className="col-lg-3 col-xs-3 brand-logo">
            <img className="w-100" src={Brand1} alt="brand" />
          </div>
          <div className="col-lg-3 col-xs-3 brand-logo">
            <img className="w-100" src={Brand2} alt="brand" />
          </div>
          <div className="col-lg-3 col-xs-3 brand-logo">
            <img className="w-100" src={Brand3} alt="brand" />
          </div>
          <div className="col-lg-3 col-xs-3 brand-logo">
            <img className="w-100" src={Brand4} alt="brand" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
