import React from "react";
const TabNavItem = ({ id, title, desc, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div className={activeTab === id ? "active" : ""} onClick={handleClick}>
      <h2 className="font-size-7 mb-4">{title}</h2>
      <p className="font-size-6">{desc}</p>
    </div>
  );
};
export default TabNavItem;
