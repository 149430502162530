import React from "react";


const FeatureCard = ({ options }) => {
  return (
    <div className="timeline-area d-sm-flex just justify-content-lg-between flex-wrap flex-lg-nowrap position-relative">
      <div className="square-83 d-flex rounded-10  mt-2 mx-auto mx-md-0">
        <img className="w-100" src={options.icon} alt="image" />
      </div>
      <div className="mt-5 mt-xs-0 pl-xs-6 feature">
        <h3 className="font-size-7 mb-1">{options.title}</h3>
        <p className="font-size-5 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
          {options.content}
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
