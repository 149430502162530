import React from "react";
import img from "../../assets/image/media/develop/png/DevelopHero.png";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container pt-25 pt-lg-31 pb-13 pb-lg-18">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <p className="font-size-4 mb-4">AGILE PROCESS</p>
              <h2 className="font-size-10 mb-8">
                We're making development better for everyone
              </h2>
              <p className="font-size-6 mb-0">
                Getting a product to market can be frustrating if you don’t have
                sight of what your dev team is up to. Our development process is
                designed to be as collaborative as possible — involving you at
                each stage.
              </p>
              <p>
                We move quickly through successive iterations of the product,
                allowing you to get hands-on with demonstration versions, and
                see what progress has been made in real-time. And we’ll keep you
                informed and in the loop when key decisions have to be made.
              </p>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-8 order-1 order-md-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <img className="w-100" src={img} alt="img" />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
